<script setup>
const { currentEnvironemnt, isProduction } = useEnvironment()
</script>

<template>
  <InfoBanner v-if="!isProduction" :content="currentEnvironemnt + ' Environment'" />
  <proclaim-status />
  <nuxt-link to="/">
    <div class="absolute z-10 m-8 flex items-center">
      <img src="../assets/images/avrillo-emove.png" alt="AVRillo eMove" class="w-24" />
      <h1 class="ml-4 text-xl font-bold text-primary">eMove</h1>
    </div>
  </nuxt-link>
  <div class="grid h-full grid-cols-1 md:grid-cols-3">
    <div class="relative col-span-1 hidden h-full bg-gray-50 md:block">
      <div class="flex h-full px-12">
        <img src="../assets/images/auth-image.svg" class="mx-auto w-full" />
      </div>
    </div>
    <div class="col-span-2 mt-36 md:mt-0">
      <div class="flex h-full w-full items-start justify-center px-8 text-sm sm:px-16 md:items-center">
        <slot />
      </div>
    </div>
  </div>
</template>
